#searchSubmit {
    z-index: 0;
}


 
  /* Extra small devices (portrait phones, less than 576px) */
  @media (max-width: 575.98px) { 
    .col-3{
        flex:0 0 100% !important;
        max-width: 100% !important;
    }
 }


 /* Small devices (landscape phones, 576px and up) */
@media (min-width: 576px) and (max-width: 767.98px) {
    .col-3{
        flex:0 0 100% !important;
        max-width: 100% !important;
    }
}

 /* Medium devices (tablets, 768px and up) */
 /* @media (min-width: 768px) { 
    .col-3{
        flex:0 0 25% !important;
        max-width: 25% !important;
    }
 }  */


 /* Large devices (desktops, 992px and up) */
 /* @media (min-width: 992px) { 
    .col-3{
        flex:0 0 25% !important;
        max-width: 25% !important;
    }
 }  */

/* Extra large devices (large desktops, 1200px and up) */ 
 /* @media (min-width: 1200px) { 
    .col-3{
        flex:0 0 25% !important;
        max-width: 25% !important;
    }
 }  */